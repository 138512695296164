import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core'
const Loading = () => {
    return (
        <Grid container spacing={0} align="center" justifyContent="center">
            <Grid item xs={12} sm={6} style={{ alignSelf: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}

export default Loading

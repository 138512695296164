import Axios from 'axios';
import { get, rem } from './localSecure';
import {
    API_URL,
    JWT_TOKEN,
    AUTH_SERVICE_API
} from 'config'

import { errorMessage } from './alertMessage';

const axios = Axios.create({
    baseURL: API_URL,
});

axios.interceptors.request.use((config) => {
    // Read token for anywhere, in this case directly from localStorage
    const token = get('token') || JWT_TOKEN;
    if (token) {
        config.headers = {
            'X-App-Token': token
        };
    }
    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        errorMessage(error.response ? error.response.data.message : error.message);
        if (error.response && error.response.data) {
            if (error.response.status === 401) {
                rem();
                window.location.href = '/';
            }
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    }
);

export const docAPi = {
    login: (data) => axios.post(`/login`, data),
    generate: (data) => axios.post(`/generate`, data),
    getListDoc: () => axios.get('/docs'),
    getDoc: (project, isDownload = false) =>
        axios.get(`/docs/${project}`, { responseType: isDownload && 'blob' }),
    status: () => axios.get('/mock/status'),
    mockSetting: (status, project) => axios.post(`/mock/${status}/${project}`),
    sso: () => axios.get(`/sso`,{ withCredentials:true }),
    getOpenapiList: () => axios.get('/openapi')
};

const axiosAuth = Axios.create({
    baseURL: API_URL,
});

axiosAuth.interceptors.request.use((config) => {
    // Read token for anywhere, in this case directly from localStorage
    const user = get('user') || JWT_TOKEN;
    if (user) {
        config.headers = {
            'Authorization': `Bearer ${user.token}`
        };
    }
    return config;
});

axiosAuth.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        errorMessage(error.response ? error.response.data.message : error.message);
        if (error.response && error.response.data) {
            if (error.response.status === 401) {
                rem();
                window.location.href = '/';
            }
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    }
);

export const authService = {
    verify: (code) => Axios.post(`${AUTH_SERVICE_API}/google/verify`, { code }),
    login: (body) => Axios.post(`${AUTH_SERVICE_API}/partner/login`, body),
    getProfile: (token) => Axios.get(`${AUTH_SERVICE_API}/user/profile`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }),
    listPartner: (params) => axiosAuth.get(`${AUTH_SERVICE_API}/partner`, {
        params: params,
    }),
    getPartner: (id) => axiosAuth.get(`${AUTH_SERVICE_API}/partner/detail/${id}`, {
    }),
    addPartner: (data) => axiosAuth.post(`${AUTH_SERVICE_API}/partner`, data, {
    }),
    updatePartner: (id,data) => axiosAuth.put(`${AUTH_SERVICE_API}/partner/${id}`, data, {
    }),
    deletePartner: (id) => axiosAuth.delete(`${AUTH_SERVICE_API}/partner/${id}`, {
    })
}

export const axiosOpenapi = {
    getOpenapiDoc: (projectUrl, opt = {}) => {
        const {
            isDownload = false,
            isRetryAttempt = false,
            shouldIncludeAuthHeader = false
        } = opt
        const token = get('token')
        const headers = shouldIncludeAuthHeader && (isRetryAttempt ? {
            'X-App-Token': JWT_TOKEN
        } : {
            'Authorization': `Bearer ${token}`
        })
        return Axios.get(projectUrl, { headers, responseType: isDownload && 'blob' })
    }
}

export default axios;

import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom'
import Loading from 'components/Loading'
import VendorForm from "components/vendor/FormVendor"
import initSentry from 'lib/initSentry'

const PrivateRoute = React.lazy(() => import('./components/PrivateRoute'));
const Verify = React.lazy(() => import('./pages/verify'));
const Login = React.lazy(() => import('./pages/login'));
const Docs = React.lazy(() => import('./pages/docs'));
// const Generate = React.lazy(() => import('./pages/generate'));
const Vendor = React.lazy(() => import('./pages/vendor'))

class App extends Component {
  componentDidMount() {
    const {
      REACT_APP_ENV,
      REACT_APP_SENTRY_DSN,
      REACT_APP_VERSION,
    } = process.env

    
    initSentry({
      DSN: REACT_APP_SENTRY_DSN,
      version: REACT_APP_VERSION,
      environment: REACT_APP_ENV,
    })
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                component={Login}
              />
              <Route
                exact
                path="/verify"
                name="Verify Page"
                component={Verify}
              />
              {/* <PrivateRoute exact path="/generate" name="Vendor Generate" component={Generate} /> */}
              <PrivateRoute exact path="/vendor" name="Vendor List" component={Vendor} />
              <PrivateRoute exact path="/vendor/create" name="Create Vendor" component={VendorForm} />
              <PrivateRoute path="/vendor/:id" name="Update Vendor" component={VendorForm} />
              <PrivateRoute exact path="/" name="Home" component={Docs} />
              <PrivateRoute path="/:doc" name="Docs" component={Docs} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;

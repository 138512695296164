import React, { useEffect, useState } from 'react'
import { get } from 'lib/localSecure'
import { Card, CardContent, Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import { authService } from 'lib/apiCall'
import { successMessage } from 'lib/alertMessage';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function replaceUndefinedOrNull(key, value) {
    if (value === null || value === undefined || value === '') {
        return undefined;
    }

    return value;
}

const Generate = ({ ...props }) => {
    const isEdit = props.match.params.id
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        company: '',
        phone: ''
    });

    const user = get('user')
    const history = props.history
    useEffect(() => {
        if (isEdit) {
            authService.getPartner(isEdit).then(result => {
                setValues({ ...result.data, password: "" })
            })
        }
        if (!user.is_admin) {
            history.push('/')
        }

    }, [history, user.is_admin, isEdit])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const body = JSON.parse(JSON.stringify(values, replaceUndefinedOrNull));
        !isEdit ? authService.addPartner(body).then((vendor) => {
            setValues(vendor.data)
            setLoading(false);
            successMessage("Success")
            history.push('/vendor')
        }).catch(() => {
            setLoading(false);
        }) : authService.updatePartner(isEdit, body).then((vendor) => {
            setValues(vendor.data)
            setLoading(false);
            successMessage("Update Success")
        }).catch(() => {
            setLoading(false);
        })
    }



    return (
        <Grid container spacing={0} align="center" justifyContent="center">
            <Grid item xs={12} sm={6} style={{ alignSelf: 'center' }}>
                <Card style={{ maxWidth: '80%', overflow: "visible" }}>
                    <CardContent>
                        <Typography
                            variant="h5"
                            style={{ marginTop: 16, marginBottom: 16 }}>
                            {isEdit ? "Update Vendor Account" : "Create Vendor Account"}
                        </Typography>
                        <ValidatorForm
                            onSubmit={handleSubmit}
                            onError={errors => console.log(errors)}
                        >
                            <TextValidator
                                label="Name"
                                onChange={handleChange('name')}
                                name="name"
                                value={values.name}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <TextValidator
                                label="Email"
                                onChange={handleChange('email')}
                                name="email"
                                value={values.email}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                                fullWidth
                            />
                            <TextValidator
                                label="Password"
                                onChange={handleChange('password')}
                                name="password"
                                value={values.password}
                                validators={['required', 'minStringLength:8']}
                                errorMessages={['this field is required', 'password al least eight-character']}
                                fullWidth
                            />
                            <TextValidator
                                label="Company"
                                onChange={handleChange('company')}
                                name="company"
                                value={values.company}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <TextValidator
                                label="Phone"
                                onChange={handleChange('phone')}
                                name="phone"
                                value={values.phone}
                                validators={['required', 'isNumber', 'minStringLength:10', 'maxStringLength:13']}
                                errorMessages={['this field is required', 'phone number not valid', 'min 10 length', 'max 13 length']}
                                fullWidth
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: 16 }}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} color="secondary" /> : isEdit ? "Update" : "Create"}
                            </Button>
                            {!isEdit && <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ marginTop: 16 }}
                                onClick={() => setValues({
                                    name: '',
                                    email: '',
                                    password: '',
                                    company: '',
                                    phone: ''
                                })}
                            >
                                Reset
                            </Button>}
                            <Button
                                variant="contained"
                                fullWidth
                                style={{ marginTop: 16 }}
                                onClick={() => history.goBack()}
                            >
                                Back
                            </Button>
                        </ValidatorForm>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Generate
export const API_URL = process.env.REACT_APP_API_URL
export const JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN
export const LOCAL_STORAGE_CONTENT = process.env.REACT_APP_LOCAL_STORAGE_CONTENT
export const LOCAL_STORAGE_TITLE = process.env.REACT_APP_LOCAL_STORAGE_TITLE
export const AUTH_SERVICE_API = process.env.REACT_APP_AUTH_SERVICE

export const REACT_APP_ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL
export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const REACT_APP_STAGE = process.env.REACT_APP_ENV


export const AUTH_CALLBACK = window.location.origin + '/verify'
export const AUTH_SERVICE_GOOGLE_URL =
  `${AUTH_SERVICE_API}/sso`
export const AUTH_SERVICE_GOOGLE_REDIRECT =
  AUTH_SERVICE_GOOGLE_URL + '?redirect_url=' + AUTH_CALLBACK

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const initSentry = ({ DSN, version, environment }) => {
  const hasDSN = Boolean(DSN)
  if (hasDSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],

      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      release: version,
      environment,
    })
    console.info('Sentry installed')
  }
}

export default initSentry
